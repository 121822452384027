<template>
<div>
	<el-card class="box-card">
		<el-form  class="demo-form-inline">
			<el-row>
			  <el-col :span="12">
			  	<el-form-item label="订单号">23532532434</el-form-item>
			  	<el-form-item label="下单时间">2020-12-20</el-form-item>
			  	<el-form-item label="支付方式">微信支付</el-form-item>
			  </el-col>
			  
			  <el-col :span="12">
			  	 <el-form-item label="订单时间">dfg</el-form-item>
			  	 <el-form-item label="订单状态">已付款</el-form-item>
			  	 <el-form-item label="订单时间">dfg</el-form-item>
			  </el-col>
			</el-row>
		</el-form>
    </el-card>
</div>
</template>
<script type="text/javascript">
export default{

data(){
	return{}
},
mounted(){
  console.log(this.$route.query)
},
methods:{

}

};
</script>

<style type="text/css" scoped>
.box-card{
	margin:20px;
}
</style>